// src/components/PrivacyPolicy.js
import React from 'react';
import './CookiePolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const CookiePolicy = () => {
  return (
    <div>
      <Navbar />
      <br /><br /><br /><br />
      <div className="policy-container">
        <div className="breadcrumb">
          <h2>Privacy Policy</h2>
        </div>
        <div className="policy-content">
          <div className="info-box">
            <div className="info-icon">
              <i className="fas fa-shield-alt"></i>
            </div>
            <div className="info-details">
              <h3>Privacy</h3>
              <p>We are committed to maintaining your trust and protecting your privacy.</p>
            </div>
          </div>
        </div>
        <div className="policy-body">
          <h1>Privacy Policy</h1>
          <p>
            Global Auto Experts ("Global Auto Experts," "we," "us," or "our") is dedicated to maintaining your confidence and trust with respect to the privacy of any personally identifiable information we collect from you when you use our parts locating websites, applications, and/or services (the “Services”). Please read this privacy policy carefully to understand our practices about how we collect, use, and share Collected Data (as defined below).
          </p>

          <h2>About Our Privacy Policy</h2>
          <p>
            This privacy policy describes how we may use any Collected Data that is collected when you use any of the Services.
          </p>

          <h2>Collected Data</h2>
          <p>
            Global Auto Experts has the right to collect data regarding the use of our websites, products, and/or services (including, without limitation, data or information regarding the relationship between vehicle identification numbers and the parts included in such vehicle, information regarding the part searches performed by users of the Services, and information regarding how users navigate and/or use the Services) (such data and information being collectively referred to as “Collected Data”).
          </p>
          <p>
            By way of example, and not of limitation, Collected Data may include the following types of data and information:
          </p>
          <ul>
            <li>Username(s) and password(s) used to access the Services (if applicable);</li>
            <li>Data and information regarding the relationship between vehicle identification numbers and the parts included in such vehicle;</li>
            <li>Data and information regarding part searches performed by users of our websites, products, and services;</li>
            <li>Data and information that tell us how you navigate and/or use our websites, products and/or services (which data and information may be collected through our servers and through the use of cookies, web beacons, and other technologies);</li>
            <li>Data and information regarding parts ordered and/or added to estimates;</li>
            <li>Internet Protocol (“IP”) address used to connect your computer to the Internet;</li>
            <li>Computer, device and connection information, such as browser type and version, operating system, mobile platform and unique device identifier (“UDID”) and other technical identifiers;</li>
            <li>Uniform Resource Locator (“URL”) click stream data, including date and time stamp, referring and exit URLs, search terms you used, and pages you visited or searched for on the Service; and</li>
            <li>For location-aware services, the physical location of your device.</li>
          </ul>
          <p>
            Global Auto Experts does not intend to collect personally identifiable information (as defined below) about users of the Services. If the Collected Data does contain personally identifiable information about any user of the Services, Global Auto Experts does not intend to disclose such personally identifiable information to any other party (except as set forth under Disclosure of Collected Data below, or with such user’s consent); provided, however, that Global Auto Experts may use any such personally identifiable information internally for the purposes identified herein. As used herein, “personally identifiable information” shall mean an individual’s first name or first initial and last name in combination with any one or more of the following data elements, when the name or data element is not redacted: (1) Social Security number; (2) driver’s license number; or (3) account number, credit or debit card number, in combination with any required security code, access code, or password that would permit access to an individual’s financial account.
          </p>

          <h2>Manner of Collection; Use of Cookies</h2>
          <p>
            Cookies are small text files that are stored on your computer’s hard drive by websites you visit. Our websites may make use of cookies that help us analyze how you use the website. This helps us improve our websites, products, and services. The cookies we use in no way give us access to your computer or any personally identifiable information about you, other than the data you choose to share with us.
          </p>
          <p>
            You can prevent your browser from accepting certain cookies, have the browser require your consent before a new cookie is placed in your browser, or block cookies altogether by selecting the appropriate settings on your browser's privacy preferences menu. To avoid receiving cookies on the browser of your mobile device, you will need to refer to its user manual. However, disabling cookies in your browser may affect your use of the Services.
          </p>
          <p>
            Our website uses Google Analytics (see <a href="http://www.google.com/analytics/">http://www.google.com/analytics/</a>), a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies” which are text files placed on your computer, to help the website analyze how users use the website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. By using our website or the Services, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
          </p>
          <p>
            For more information on how Google collects and processes data, please visit the following link: <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>. For information on how to opt-out of cookies provided by Google Analytics, please visit the following link: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>. You can also stop being tracked by Google Analytics across all websites by going to Google's site at: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
          </p>
          <p>
            We believe there is no reason for our visitors to be particularly concerned about the privacy implications of any of the cookies we use, but we do recognize the importance of making you aware of the purpose and scope of the data stored in the cookies which are created or updated on your computer when you visit our website. You may choose to delete these cookies from your computer between visits to our website.
          </p>import CookiePolicy from './CookiePolicy';


          <h2>Permitted Uses of Collected Data</h2>
          <p>
            Global Auto Experts owns any and all Collected Data. Global Auto Experts has the unlimited right to use the Collected Data, combine the Collected Data with other information, prepare works based upon the Collected Data, include or incorporate Collected Data into the products or services of Global Auto Experts or any of its affiliates, and otherwise use the Collected Data to enhance any of the products or services of Global Auto Experts or any of its affiliates. Global Auto Experts does not intend to collect personally identifiable information about users of the Services. If the Collected Data does contain personally identifiable information about any user of the Services, Global Auto Experts does not intend to disclose such personally identifiable information to any other party (except as set forth under Disclosure of Collected Data below, or with such user’s consent); provided, however, that Global Auto Experts may use any such personally identifiable information internally for the purposes identified herein.
          </p>
          <p>
            Global Auto Experts may use and disclose Collected Data in any manner permitted under applicable law. By way of example, and not of limitation, the purposes for which Global Auto Experts may use Collected Data include the following:
          </p>
          <ul>
            <li>Combining the Collected Data with other information;</li>
            <li>Preparing works based upon the Collected Data;</li>
            <li>Including or incorporating Collected Data into the products and/or services of Global Auto Experts or any of its affiliates;</li>
            <li>Using the Collected Data to enhance any of the products and/or services of Global Auto Experts or any of its affiliates;</li>
            <li>Providing any product or service to our users (such as allowing access to or delivering our products or services, processing or fulfilling an order or transaction, or administering surveys or promotional programs);</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
