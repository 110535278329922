import React from 'react';
import './ShippingPolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const ShippingPolicy = () => {
  return (
    <div>
        <Navbar/> <br/> <br/> <br/> <br/>
        <div className="policy-container">
      <header className="policy-header">
        <h1>Shipping Policy</h1>
      </header>
      <section className="policy-content">
        <h2>Domestic Shipping</h2>
        <p>
          We offer free standard shipping on all orders within the United States. Orders are processed within 2-3 business days and shipped via our preferred carriers. Expedited shipping options are available at an additional cost.
        </p>
        <h2>International Shipping</h2>
        <p>
          International shipping is available to select countries. Shipping costs and delivery times vary based on destination. Customers are responsible for any customs fees, import taxes, or other related charges.
        </p>
        <h2>Order Tracking</h2>
        <p>
          Once your order is shipped, you will receive a tracking number via email. You can use this number to track the status of your shipment on the carrier's website.
        </p>
        <h2>Shipping Issues</h2>
        <p>
          If you experience any issues with your shipment, such as delays or lost packages, please contact our customer support team for assistance. We will work with the carrier to resolve the issue promptly.
        </p>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default ShippingPolicy;
