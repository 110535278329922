import React from 'react';
import './ReturnPolicy.css';
import Footer from './Footer';
import Navbar from './Navbar';

const ReturnPolicy = () => {
  return (
    <div>
        <Navbar/> <br/> <br/> <br/> <br/>
        <div className="policy-container">
      <header className="policy-header">
        <h1>Return Policy</h1>
      </header>
      <section className="policy-content">
        <h2>30-Day Return Guarantee</h2>
        <p>
          We offer a 30-day return guarantee on all products. If you are not satisfied with your purchase, you may return it within 30 days of the delivery date for a full refund or exchange.
        </p>
        <h2>Return Process</h2>
        <p>
          To initiate a return, please contact our customer support team to obtain a return authorization number. Include this number with your return package.
        </p>
        <h2>Condition of Returned Items</h2>
        <p>
          Returned items must be in their original condition, unused, and with all original packaging and tags attached. We reserve the right to refuse returns that do not meet these criteria.
        </p>
        <h2>Refunds</h2>
        <p>
          Once we receive and inspect your return, we will process your refund within 7-10 business days. Refunds will be issued to the original payment method used for the purchase.
        </p>
        <h2>Exchanges</h2>
        <p>
          If you would like to exchange an item, please follow the return process and place a new order for the desired item.
        </p>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default ReturnPolicy;
