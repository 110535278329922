import React from 'react';
import './TermsAndConditions.css';
import Navbar from './Navbar';
import Footer from './Footer';

const TermsAndConditions = () => {
  return (
    <div>
        <Navbar/> <br/> <br/> <br/> <br/>
        <div className="terms-and-conditions">
      <div className="breadcrumb">
        <h2>Terms & Conditions</h2>
      </div>
      <div className="terms-content">
        <div className="info-box">
          <div className="info-icon">
            <i className="fas fa-clock"></i>
          </div>
          <div className="info-details">
            <h3>Working Hours</h3>
            <p>Mon - Fri: 10:00 am - 6:30 pm</p>
          </div>
        </div>
        <div className="info-box">
          <div className="info-icon">
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <div className="info-details">
            <h3>Location</h3>
          <p>30S 15TH ST STE 1550 NUM 173221</p>
        <p>PHILADELPHIA, PA 19102</p>
        <p>info@globalautoexperts.us</p>
        <p>+1 215-966-4473</p>
          </div>
        </div>
        <div className="info-box">
          <div className="info-icon">
            <i className="fas fa-phone"></i>
          </div>
          <div className="info-details">
            <h3>Sale Support</h3>
            <p>+1 215-966-4473</p>
          </div>
        </div>
      </div>
      <div className="terms-body">
      <h1>PRIVACY POLICY</h1>
      <p>Your privacy is important to us at Global Auto Experts, and we are committed to protecting the privacy of our visitors. Here is our Privacy Policy:</p>
      <h2>Information We May Collect</h2>
      <p>
        We may collect Personal Information, which identifies you as an individual, such as your name, address, telephone number, and email address, among others. We may also collect Non-Personal Information, which does not reveal your specific identity, such as computer or device type, browser information, internet domain address, domain server, IP address, usage activity, information collected through cookies, pixel tags, and other technologies, preferences and settings, geographic or demographic information, and other aggregated or general traffic information provided by you.
      </p>
      <p>
        Please do not send, provide, or disclose any sensitive Personal Information (for example, Social Security number, driver’s license number, credit card number (unless necessary to make a purchase), racial or ethnic origin, political, religious, or other beliefs, organizational memberships or group affiliations, or criminal backgrounds) on or through the Site or otherwise to us. We do not need this information.
      </p>
      <h2>How We May Use or Disclose Information Collected</h2>
      <p>
        We may use your Personal and Non-Personal Information to respond to your inquiries, connect you with vendors, and other third parties who will provide you with information about products and services. Vendors and other affiliates may contact you to fulfill your requests for our administrative and operational purposes, such as conducting data analysis and audits, developing new products, enhancing our website, improving our services, identifying usage trends, or determining the effectiveness of our product or service campaigns.
      </p>
      <h3>Your Information May Be Disclosed:</h3>
      <ul>
        <li>
          To third-party service providers who provide services in conjunction with the fulfillment of our services such as customer service, website hosting, data analysis, infrastructure provision, IT services, etc.
        </li>
        <li>
          If you request information from vendors so that they (or third parties who provide similar services) can provide you with information about their products and services. We are not responsible or liable for vendors’ use of your information; please refer to their privacy policies.
        </li>
        <li>
          To a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).
        </li>
        <li>
          As we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities including those outside your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.
        </li>
      </ul>
      <p>
        Furthermore, your information may be stored and processed in any country where we have facilities, and by providing any information to us, you consent to the transfer of such Personal Information to countries outside your country of residence, which may have different data protection rules.
      </p>
      <h2>Third Party Sites</h2>
      <p>
        Global Auto Experts has links to independent external sites, and this Privacy Policy does not apply to those sites. This Policy is applicable only to this Site and not to any other websites that you may access from this Site, each of which may collect data and use it in a manner that materially differs from our Policy. Although certain third-party websites may appear to be part of this Site, this Site does not necessarily own or operate any of these third-party websites and is not responsible for their privacy policies, practices, or content.
      </p>
      <p>
        Once you leave our site, the respective third party’s privacy policy will be in effect. We do not warrant or make any representations about any such websites, information, software, services, or products found therein or any results that may be obtained from using such websites. If you decide to access any of the third-party sites linked to this Site, your access, use of, or interaction with such other websites is entirely at your own risk.
      </p>
      <p>
        Our Site may also include social media features, such as the Facebook Like or Tweet buttons. These widgets may also collect your IP address and other information, and may set a cookie to function properly. Please note that your interaction with these widgets is also governed by the privacy policy of the vendor providing it.
      </p>
      <p>
        Lastly, please note that we are not responsible or liable for the acts and/or omissions of third parties and vendors.
      </p>
      <h2>Security</h2>
      <p>
        We use reasonably practical technical and administrative measures to protect information under our control. Unfortunately, due to the nature of the Internet, no data transmission over the Internet or data storage systems can be guaranteed to be 100% secure. Should you have reason to believe that your interaction on our Site is no longer secure, please contact us immediately atinfo@globalautoexperts.com.
      </p>
      <p>
        If you would like to have your information removed from our data storage systems, please contact us, and we will endeavor to comply with your request in a reasonable manner. Please note, however, that certain information may remain in archive for up to ninety days due to the nature of automatic data storage systems. Additionally, we may still send you important administrative messages that you cannot opt out of receiving.
      </p>
      <p>
        Unfortunately, we are not able to remove your information from the databases of third parties. Should you wish to cease receiving marketing-related e-mails from these third parties, please contact them directly or utilize their appropriate opt-out mechanisms in their privacy policies or e-mails.
      </p>
      <h2>Use of Site by Children</h2>
      <p>
        The Site is not intended for or directed to individuals under the age of thirteen (13). If you are under thirteen, please do not provide us with any information. By providing information, you warrant and represent that you are thirteen or above.
      </p>
      <p>
        We do not knowingly collect personal information from children under the age of 13. If we become aware that a child under 13 has provided us with personal information, we will remove such information and terminate the minor’s account. If you become aware that a child has provided us with personal information without parental consent, please contact us at info@globalautoexperts.com. Global Auto Experts is committed to respecting the privacy of children online.
      </p>
      <h2>Changes and Updates</h2>
      <p>
        We reserve the right to add, update, modify, or otherwise change this Privacy Policy at our option any time without notice. All changes to this Privacy Policy will become effective immediately once posted on the Site. Please review it frequently, as your continued and subsequent use of the Site constitutes acceptance of the Privacy Policy and any updates.
      </p>
      </div>
    </div>
    <Footer/>
    </div>

  );
};

export default TermsAndConditions;
