import React, { useState } from 'react';
import './QuoteForm.css';

const QuoteForm = () => {
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');

  const carModels = {
    "Maruti Suzuki": ["Brezza", "Swift", "Swift Dzire", "Baleno", "Wagon R", "Celerio", "Alto K10", "Eeco", "Ertiga", "Ciaz", "Ignis", "Grand Vitara", "Jimny", "XL6", "Fronx", "Invicto", "eVX", "Gypsy", "Omni", "SX4", "Maruti 800", "Kizashi", "Esteem"],
    "Hyundai": ["i10", "i20", "Verna", "Creta", "Venue", "Tucson"],
    "Honda": ["City", "Civic", "Amaze", "Jazz", "WR-V", "CR-V"],
    "Tata": ["Nexon", "Altroz", "Harrier", "Safari", "Tiago", "Tigor"],
    "Ford": ["Ecosport", "Figo", "Endeavour", "Aspire", "Freestyle"],
    "Volkswagen": ["Polo", "Vento", "Tiguan", "Ameo", "Jetta"],
    "Mahindra": ["Bolero", "Scorpio", "XUV300", "Thar", "Bolero Neo", "XUV700", "Marazzo", "XUV900", "XUV400", "KUV100", "NuvoSpot", "Scorpio Classic", "TUV300", "XUV500", "Verito", "Camper", "XUV400 EV", "Genio", "XUV500 2024"],
    "Renault": ["Kwid", "Kiger", "Triber", "Duster", "Captur", "Pulse", "Fluence", "Koleos", "Dacia Lodgy", "Megane E-Tech Electric", "Kwid EV", "Scala", "Zoe", "Laguna", "Rafale"],
    "Toyota": ["Camry", "Glanza", "Urban Crusier Hyryder", "Innova Crysta", "Hilux", "Fortuner", "Innova Hycross", "Rumion", "Land Cruiser", "Vellfire", "Corolla", "Belta", "Legender", "A1", "Taisor", "Avalon", "86"],
    "Skoda": ["Kodiaq", "Kushaq", "Slavia", "Octavia", "Superb", "Enyaq", "Rapid", "Fabia", "Karoq", "Kodiaq 2024", "Yeti", "Slavia"],
    "Nissan": ["Juke", "Nissan Magnite", "Kicks", "Qashqai", "Micra", "Sunny", "Terrano", "Leaf", "Armada", "Compact MPV", "Murano", "Evalia"],
    "Fiat": ["Punto", "Linea", "Punto Evo", "Palio D", "Avventura", "Palio Stile", "Lines Classic", "Adventure", "Palio NV", "Abarth Punto", "Urban Cross", "Uno", "Petra"],
    "Datsun": ["redi-GO", "Go", "Go Plus"],
    "BMW": ["X1", "3 Series", "2 Series", "7 Series", "1 Series", "Z4", "5 Series GT", "X5", "X6", "6 Series", "M5", "M3", "X4", "6 Series GT", "X7", "i4", "iX"],
    "KIA": ["Carnival", "Seltos", "Carens", "Sonet", "EV6"],
    "Jeep": ["Compass", "Wrangler"],
    "Mitsubishi": ["Pajero Sport", "Outlander", "Pajero", "Montero", "Cedia", "Lancer"],
    "MG": ["Gloster", "ZS EV", "Hector", "Astor", "Hector Plus"],
    "Land Rover": ["Discover Sport", "Range Rover Evoque", "Freelander 2", "Range Rover Sport", "Discover 4", "Defender", "Range Rover", "Range Rover Vogue", "Range Rover Vrlar"],
    "Volvo": ["S60", "V40", "S80", "XC60", "XC90", "S60 Cross Country", "V40 Cross Country", "S40", "XC 40", "V60", "V90", "S90"],
    "Jaguar": ["XF", "XE", "XJ", "F Type", "F Pace", "XJR", "XK R", "i-Pace", "XJ L"],
    "Force": ["One", "Traveller 3350", "Trax", "Gurkha"],
    "Opel": ["Corsa", "Astra"],
    "Porsche": ["911", "Cayenne", "Macan", "Cayman", "Panamera", "Boxter", "Taycan", "Taycan Turismo"],
    "Daewoo": ["Matiz", "Nexia", "Cielo"],
    "Hindustan Motors": ["Ambassador"],
    "Aston Martin": ["Vantage", "Rapide", "VAnquish", "DB9"],
    "Citroen": ["C5 Aircross", "C3"],
    "Lexus": ["Es", "NX", "LS", "RX", "LC", "LX"],
    "Bentley": ["Mulsanne", "Continental", "Flying Spur"],
    "Lamborghini": ["Huracan", "Aventador", "Gallordo", "Urus"],
    "Rolls Royce": ["Phantom", "Ghost", "Wraith"],
    "Photon": ["VIW CS2"],
    "Jayem": ["Neo"],
    "Premier": ["Rio"],
    "Hummer": ["H2", "H3"],
    "BYD": ["e6"],
  };

  const handleMakeChange = (e) => {
    setSelectedMake(e.target.value);
    setSelectedModel('');
  };

  return (
    <div className="quote-form-container-form">
      <h1 className="quote-title-form">Get Your <span className="highlight">FREE Price Quote</span> Now</h1>
      <p className="quote-description-form">
        Access local price quotes on over 100,000 engines/motors, transmissions, and other
        used OEM auto parts online. Buy directly from suppliers to save substantial time and money.
      </p>
      <form className="quote-form-form">
        <div className="form-group-form">
          <select className="form-control-form" required>
            <option value="" disabled selected>Select Part</option>
            <option value="Engine">Engine</option>
            <option value="Transmission">Transmission</option>
            <option value="Front Axle">Front Axle</option>
            <option value="Rear Axle">Rear Axle</option>
            <option value="Radiator">Radiator</option>
            <option value="Brakes">Brakes</option>
            <option value="Suspension">Suspension</option>
            <option value="Steering Gear">Steering Gear</option>
            <option value="Fuel Tank">Fuel Tank</option>
          </select>
          <select className="form-control-form" required onChange={handleMakeChange} value={selectedMake}>
            <option value="" disabled selected>Select Make</option>
            {Object.keys(carModels).map(make => (
              <option key={make} value={make}>{make}</option>
            ))}
          </select>
        </div>
        <div className="form-group-form">
          <select className="form-control-form" required value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
            <option value="" disabled selected>Select Model</option>
            {selectedMake && carModels[selectedMake].map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
          <select className="form-control-form" required>
            <option value="" disabled selected>Select Year</option>
            {[...Array(new Date().getFullYear() - 1979).keys()].map(year => (
              <option key={1980 + year} value={1980 + year}>{1980 + year}</option>
            ))}
          </select>
        </div>
        <div className="form-group-form">
          <input type="text" className="form-control-form" placeholder="Name" required />
          <input type="email" className="form-control-form" placeholder="Email" required />
        </div>
        <div className="form-group-form">
          <input type="tel" className="form-control-form" placeholder="Phone" required />
          <input type="text" className="form-control-form" placeholder="Zip" required />
        </div>
        <button type="submit" className="quote-button-form">Get a Quote</button>
      </form>
    </div>
  );
};

export default QuoteForm;
