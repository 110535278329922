import React from 'react';
import './ShopCategories.css';

const ShopCategories = () => {
  const products = [
    { name: 'Engine', img: 'https://wallpaperaccess.com/full/2947990.jpg' },
    { name: 'Transmission', img: 'https://www.onlineautorepair.net/wp-content/uploads/2021/07/209e9326854d9ce2453a6d74e10285eb.jpg' },
    { name: 'Transfer Case', img: 'https://transfercasewarehouse.com/wp-content/uploads/2022/08/bbnn1.png' },
    { name: 'Rear Axle', img: 'https://www.autozone.com/diy/wp-content/uploads/2019/06/new-CV-axle.jpg' },
    { name: 'ABS', img: 'https://www.thoughtco.com/thmb/bgeKtqWP1bWq-U4pHc7G-SEn4Mo=/5246x3932/filters:fill(auto,1)/supercar-brake-detail--close-up-501869483-5b35950646e0fb00370d9b36.jpg' },
    { name: 'Drive Shaft', img: 'https://www.westerndiff.com/sites/default/files/Driveshaft.jpg' },
    { name: 'Temperature Control Unit', img: 'https://www.carid.com/images/pages/ac-control-units/ac-control-unit.jpg' },
    { name: 'Cylinder Head', img: 'http://d3d71ba2asa5oz.cloudfront.net/62001617/images/ff281.1001-7.jpg' },
    { name: 'Steering Column', img: 'https://i1.wp.com/dsauto.com.my/wp-content/uploads/2018/09/Steering-Rack-Connected-To-Steering-Wheel.jpg?fit=1024%2C1024&ssl=1' },
    { name: 'Engine Cradle', img: 'https://www.techman-head.com/app/uploads/2022/07/TMH-EC29-00-00.jpg' },
    { name: 'Module', img: 'https://conti-engineering.com/wp-content/uploads/2020/05/HPTPMI_web.png' },
    { name: 'Radio', img: 'https://wallpaperaccess.com/full/4581263.jpg' },
  ];

  return (
    <div className="shop-container">
      <h1>Our Products</h1>
      <div className="product-list-shop">
        {products.map((product, index) => (
          <div className="product-item-shop" key={index}>
            <img src={product.img} alt={product.name} />
            <h3>{product.name}</h3>
            {/* <a href={`/shop/${product.name.toLowerCase().replace(/ /g, '-')}`}>Shop Now</a> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopCategories;
