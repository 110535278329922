import React from 'react';
import './Testimonials.css';

const testimonials = [
  {
    image: "https://img.freepik.com/premium-vector/cute-smiling-boy-avatar-flat-style-vector-illustration_710508-1235.jpg?size=338&ext=jpg&ga=GA1.1.2116175301.1719014400&semt=ais_user",
    description: "The team at Global Auto Experts is top-notch. Their customer service is unmatched and they fixed my car's issues in no time. Thank you so much!",
    name: "Michael Johnson",
  },
  {
    image: "https://www.premadegraphics.com/img_1/23/Female-Avatar-2.png",
    description: "I'm so glad I chose Global Auto Experts for my car maintenance. They were efficient, professional, and very affordable. Will definitely come back!",
    name: "Emily Davis",
  },
  {
    image: "https://static.vecteezy.com/system/resources/thumbnails/004/899/680/small_2x/beautiful-blonde-woman-with-makeup-avatar-for-a-beauty-salon-illustration-in-the-cartoon-style-vector.jpg",
    description: "Global Auto Experts did a fantastic job on my car's transmission. The whole process was smooth and hassle-free. Great service and great people!",
    name: "Rachel David",
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">Testimonials</h2>
      <div className="testimonials-wrapper">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img src={testimonial.image} alt="" className="testimonial-image" />
            <p className='testimonial-description'>{testimonial.description}</p>
            <p className="testimonial-name">{testimonial.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
