import React from 'react';
import './Paragraphs.css';

const Paragraphs = () => {
  return (
    <div className="global-auto-experts">
      <section className="section-paragraphs">
        <h2>How does Global Auto Experts work?</h2>
        <p>
          Simple. Browsing through our website is just a breeze - within a few minutes, you can find everything you’ve been looking for. Just fill in the required fields, such as the brand, car model, year, etc., and find the most affordable and top-quality parts.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Used Auto Parts Near You</h2>
        <p>
          To make the searching process easier for our customers, we added an option to search by city. As we provide services in all US States, it can be hard for a customer to find the ones that are close to them out of all. So, to make your task simple, just choose your state, city, and zip code to locate used car parts from junkyards near you.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Shipping all around the country</h2>
        <p>
          No matter from which corner of the USA you are ordering, you will get the auto parts delivered straight to your door. Can it get any better? Just save your precious time, order your desired used car parts within a day and get them shipped to the needed location.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Got any more questions?</h2>
        <p>
          Get in touch with us through our toll-free number +1 (215) 966-4473.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Need highly durable yet affordable used car parts?</h2>
        <p>
          You’re in the right place. Here, at Global Auto Experts, you can find the best deals in the country. The process is made simple, so our customers can easily find their desired auto parts at the best prices while sitting at home or office. No matter what type of vehicle you have, we guarantee that you can easily find what you are looking for with just a few clicks. Save your time and money, find and order all kinds of bespoke used auto parts with unbelievably low prices now!
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Our services</h2>
        <p>
          Global Auto Experts offers top-of-the-line used car auto parts for all types of vehicles, such as Honda, Acura, Dodge, Ford, BMW, you name it. On our online platform, you get the chance to browse through all the best junkyards in the country within minutes. We don’t sell the parts ourselves, but we help you get the best deals possible.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Easy navigation</h2>
        <p>
          Browse easily through our website with our simple search engine. Just fill in all the required information, such as vehicle brand name, model, and year - press the search button and that’s it. You will get a wide variety of results related to the provided details – which will make the searching process way easier and faster.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>The largest online selection of used car parts</h2>
        <p>
          Our online platform provides a wide choice of high-quality used auto parts from the best junkyards across the country. You can find parts for all types of vehicles – be it a sedan, jeep, minivan, and more. So, you get quality parts at affordable prices. This is a win-win situation and the best deal you can get.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Best prices in the industry</h2>
        <p>
          Taking care of your vehicle can be costly… but not with used parts. Getting used car parts will cost you double or even three times less than the new ones. Besides, here you can find not only very affordable used parts but also top-quality parts that have the same durability as the new ones.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Buy straight away!</h2>
        <p>
          Here you can buy your chosen car parts immediately from the junkyard and salvage yard dealers. No need to find dealers, drive around the city to get the best deal, you have it all here. Cut out the extra efforts and get straight to the business!
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>Quick turnaround</h2>
        <p>
          After you place your request with your contact information, you will get a response from junkyard or salvage yard dealers within 24 hours. If they have your requested parts they will send you an email with all the required information.
        </p>
      </section>

      <section className="section-paragraphs">
        <h2>What makes us the best?</h2>
        <p>
          We take pride to call ourselves the best online platform in the country for used auto parts. How come? You may wonder. Well…
        </p>
        <ul>
          <li>We have the most user-friendly interface that makes the searching process 10 times easier, so even a child can navigate through our website.</li>
          <li>We provide the largest catalog with all the best deals.</li>
          <li>And… we help you find your desired auto parts in less than 24 hours.</li>
        </ul>
      </section>
    </div>
  );
};

export default Paragraphs;
