import React from 'react';
import './ServicesSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd, faPercentage, faShippingFast, faCar } from '@fortawesome/free-solid-svg-icons';

const ServicesSection = () => {
  return (
    <section className="services-section">
      <div className="overlay">
        <h1>SERVICES</h1>
        <h2>PREMIUM QUALITY USED OEM PARTS</h2>
        <div className="services-container">
          <div className="service-card">
            <div className="icon">
              <FontAwesomeIcon icon={faHandHoldingUsd} />
            </div>
            <p>Get Quick Quotes and Save Money</p>
          </div>
          <div className="service-card">
            <div className="icon">
              <FontAwesomeIcon icon={faPercentage} />
            </div>
            <p>Save Over 50% on Dealer Prices</p>
          </div>
          <div className="service-card">
            <div className="icon">
              <FontAwesomeIcon icon={faShippingFast} />
            </div>
            <p>Fast Delivery to Your Home or Mechanic</p>
          </div>
          <div className="service-card">
            <div className="icon">
              <FontAwesomeIcon icon={faCar} />
            </div>
            <p>Service for All Major Makes and Models</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
