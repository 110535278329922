import React from 'react'
import Navbar from './Navbar'
import HeroSection from './HeroSection'
// import ContentSections from './ContentSections'
import Testimonials from './Testimonials'
import RotatingImageContent from './RotatingImageContent'
import QuoteForm from './QuoteForm'
import Footer from './Footer'
// import ChooseUS from './ChooseUs'
// import ResponsiveImages from './ResponsiveImages'
import ShopCategories from './ShopCategories'
// import WelcomeSection from './WelcomeSection'
import ServicesSection from './ServicesSection'
import Paragraphs from './Paragraphs'
import FooterMain from './FooterMain'
// import AssistanceBar from './AssistanceBar'

const Home = () => {
  return (
    <div>
      <Navbar/> <br/> <br/>
      <HeroSection/> <br/>
      {/* <ResponsiveImages/> <br/> */}
      {/* <ContentSections/> <br/> */}
      {/* <WelcomeSection/> <br/> */}
      <RotatingImageContent/> <br/>
      <ServicesSection/> <br/>
      <ShopCategories/> <br/>
      {/* <AssistanceBar/> <br/> */}
      <Paragraphs/> <br/>
      <QuoteForm/> <br/>
      <Testimonials/> <br/>
      {/* <ChooseUS/> <br/> */}
      <Footer/>
      <FooterMain/>
    </div>
  )
}

export default Home
