import React from 'react';
import './RotatingImageContent.css';

const RotatingImageContent = () => {
  return (
    <div className="container">
      <div className="image-section">
        <img
          src="https://www.pngmart.com/files/10/Car-Engine-PNG-HD.png"
          alt="Rotating"
          className="rotating-image"
        />
      </div>
      <div className="content-section">
        <h1>Global Auto Experts</h1>
        <p>
          Global Auto Experts Store is an esteemed online platform based in the
          United States, specializing in the procurement of various used
          automotive components. We collaborate with premier junkyards and
          salvage yards nationwide to ensure that our customers receive services
          of the highest quality.
          Our website features an intuitive search tool, enabling users to
          locate an extensive range of used car parts from multiple dealers. We
          facilitate seamless connections between customers and top suppliers,
          effectively acting as an intermediary to simplify the process.
          Upon selecting the desired used engines and transmissions and
          contacting a dealer, customers can expect a response within 24 hours.
          With mere internet access, there is no longer a need to visit multiple
          junkyards in search of specific car parts; we handle this task
          comprehensively.
          Maintaining a vehicle can often be arduous and costly. However, with
          used auto parts, significant savings are achievable. At Global Auto
          Experts Store, we alleviate the challenges associated with sourcing
          the best auto parts, ensuring that our customers receive only
          high-quality products at the most competitive prices.
        </p>
      </div>
    </div>
  );
};

export default RotatingImageContent;
