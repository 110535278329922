import React, { useState } from 'react';
import './FooterMain.css';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import authorizeNetLogo from '../assets/authorizeNetLogo.png';
import visaLogo from '../assets/visaLogo.jpg';
import masterCardLogo from '../assets/masterCardLogo.png';
import amexLogo from '../assets/amexLogo.jpg';
import discoverLogo from '../assets/discoverLogo.webp';
// import payTomorrowLogo from '../assets/payTomorrowLogo.png';

const FooterMain = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCard('');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Here you can handle the form submission, e.g., send the data to the server
    alert(`Form submitted for ${selectedCard}`);
    handleCloseModal();
  };

  return (
    <footer className="footer-main">
      <div className="footer-section-main company">
        <h3>COMPANY</h3>
        <ul>
          <li><a href="/about-us">About Us</a></li>
          <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/shipping-policy">Shipping Policy</a></li>
          <li><a href="/return-policy">Return Policy</a></li>
          <li><a href="/cookie-policy">Cookie Policy</a></li>
          <li><a href="/disclaimer">Disclaimer</a></li>
        </ul>
        <div className="payment-section">
          <h3>PAY SECURELY WITH</h3>
          <div className="payment-icons">
            <img src={authorizeNetLogo} alt="Authorize.Net" onClick={() => handleCardClick('Authorize.Net')} />
            <img src={visaLogo} alt="Visa" onClick={() => handleCardClick('Visa')} />
            <img src={masterCardLogo} alt="MasterCard" onClick={() => handleCardClick('MasterCard')} />
            <img src={amexLogo} alt="American Express" onClick={() => handleCardClick('American Express')} />
            <img src={discoverLogo} alt="Discover" onClick={() => handleCardClick('Discover')} />
          </div>
          {/* <img src={payTomorrowLogo} alt="Pay Tomorrow" className="pay-tomorrow" /> */}
        </div>
      </div>
      <div className="footer-section-main makes">
        <h3>MOST SEARCHED MAKES</h3>
        <ul className="makes-list">
          <li>Acura</li>
          <li>Alfa</li>
          <li>AMC</li>
          <li>Austin</li>
          <li>BMW</li>
          <li>Buick</li>
          <li>Chrysler</li>
          <li>Daewoo</li>
          <li>Dodge</li>
          <li>Ford</li>
          <li>Geo</li>
          <li>GMC</li>
          <li>Hyundai</li>
          <li>Infiniti</li>
          <li>Isuzu</li>
          <li>Kia</li>
          <li>LandRover</li>
          <li>Lexus</li>
          <li>Mercedes</li>
          <li>Mercury</li>
          <li>Mitsubishi</li>
          <li>Plymouth</li>
          <li>Pontiac</li>
          <li>Saab</li>
          <li>Subaru</li>
          <li>Suzuki</li>
          <li>Toyota</li>
          <li>Aston</li>
          <li>Audi</li>
          <li>Cadillac</li>
          <li>Chevrolet</li>
          <li>Eagle</li>
          <li>Fiat</li>
          <li>Honda</li>
          <li>Hummer</li>
          <li>Jaguar</li>
          <li>Jeep</li>
          <li>Lincoln</li>
          <li>Mazda</li>
          <li>Nissan</li>
          <li>Oldsmobile</li>
          <li>Saturn</li>
          <li>Scion</li>
          <li>Volkswagen</li>
          <li>Volvo</li>
        </ul>
      </div>
      <div className="footer-section-main">
        <h3>WORKING HOURS</h3>
        <p>Mon - Fri: 10:00 AM - 6:30 PM</p> <br/>
        <div>
          <h3>Follow Us</h3>
          <a href="https://www.facebook.com/globalautoexperts?mibextid=LQQJ4d" rel="noopener noreferrer" className='social-links'><FaFacebook /> Facebook</a> <br/> <br/>
          <a href="https://www.instagram.com/globalautoexperts.usa?igsh=MW1uNG9uMmJsb2MyNg%3D%3D&utm_source=qr" rel="noopener noreferrer" className='social-links'><FaInstagram /> Instagram</a>
        </div>
      </div>

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Enter Credentials for {selectedCard}</h2>
            <form onSubmit={handleFormSubmit}>
              <label>
                Card Number:
                <input type="text" name="cardNumber" required />
              </label>
              <label>
                Name:
                <input type="text" name="name" required />
              </label>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </footer>
  );
};

export default FooterMain;
