import React from 'react';
import './Footer.css';
import americanFlag from '../assets/americanFlag.jpg';

const Footer = () => {
  return (
    <div className="company-info-container">
      <div className="info-section">
        <h2>Engines & Transmissions for Sale</h2>
        <p>
          Global Auto Experts is one of the nation's largest and most trusted wholesale distributors for verified used engines, transmissions, and auto parts. We take pride in the quality of our used auto parts and provide fast, free shipping on all engines and transmissions when you use our trusted and secure online quote form. Simply fill out the form now for a free and anonymous quote, sent straight to your inbox or mobile phone — whichever you’d prefer.
        </p>
      </div>
      <div className="contact-section">
        <img src={americanFlag} alt="American Flag" />
        <h3>Proudly Serving the U.S.A.</h3>
        <p>Global Auto Experts</p>
        <p>30S 15TH ST STE 1550 NUM 173221</p>
        <p>PHILADELPHIA, PA 19102</p>
        <p>info@globalautoexperts.us</p>
        <p className="phone-number">+1 215-966-4473</p>
      </div>
    </div>
  );
}

export default Footer;
