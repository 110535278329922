// src/components/AboutUs.js
import React from 'react';
import './AboutUs.css';
import Footer from './Footer';
import FooterMain from './FooterMain';
import Navbar from './Navbar';

const AboutUs = () => {
  return (
    <div>
        <Navbar/> <br/> <br/> <br/>
        <div className="about-us-container">
      <div className="about-us-content">
        <h1>About Us</h1>
        <p>
          Global Auto Experts is a premier US-based online platform for sourcing all types of used auto parts. We collaborate with top-rated junkyards and salvage yards nationwide to ensure our customers receive only the highest quality services.
        </p>
        <p>
          Our website features a user-friendly search tool that allows you to find a wide variety of used car parts from numerous dealers. We facilitate seamless connections between you and the best suppliers, eliminating any additional effort on your part.
        </p>
        <p>
          Once you select your desired used car parts and contact a dealer, you will receive a response within 24 hours. With just an internet connection, you can avoid the hassle of visiting multiple junkyards in search of a car part – we handle it all for you.
        </p>
        <p>
          Maintaining your vehicle can be both challenging and costly, but with used auto parts from Global Auto Experts, you can save significantly. We take on the task of finding the best auto parts, ensuring you receive only high-quality products at the most affordable prices.
        </p>

        <h2>Our Benefits</h2>
        <ul>
          <li>Lowest possible prices</li>
          <li>Options to suit any budget, allowing you to choose the most comfortable option for your pocket.</li>
          <li>A vast selection of options</li>
          <li>An opportunity to choose from thousands of bespoke products.</li>
          <li>Quick turnaround in less than 24 hours</li>
          <li>Receive a response from your selected dealers within 24 hours by providing your contact details.</li>
          <li>Only the best deals in the country</li>
          <li>We offer options from only the most trusted dealers in the country, ensuring product quality and durability.</li>
          <li>Simple-to-use search tool</li>
          <li>For the best customer experience, we have made browsing our site as easy as a walk in the park. Just a few clicks, and you have what you need.</li>
        </ul>

        <h2>How Does Everything Work?</h2>
        <p>
          It’s simple. Browsing through our website is effortless - within a few minutes, you can find everything you’re looking for. Fill in the required fields, such as brand, car model, year, etc., to find the most affordable and top-quality parts.
        </p>

        <h2>Used Auto Parts Near You</h2>
        <p>
          To simplify the search process for our customers, we have added an option to search by city. As we provide services across all US states, it can be challenging for a customer to locate nearby dealers out of all available options. To make this easier, just choose your state, city, and zip code to find used car parts from junkyards near you.
        </p>

        <h2>Shipping Nationwide</h2>
        <p>
          Regardless of where you are in the USA, your auto parts will be delivered straight to your door. It doesn’t get any better than this. Save your valuable time, order your desired used car parts within a day, and have them shipped to your location.
        </p>

        <h2>Have More Questions?</h2>
        <p>
          Contact us through our toll-free number: +1 215-966-4473
        </p>
      </div>
      <Footer/> <br/>
      <FooterMain/>
    </div>
    </div>
 
  );
};

export default AboutUs;
