import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.jpg';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-left">
            <div className="navbar-logo">
              <img src={logo} alt="Global Auto Experts" />
            </div>
            <div className="navbar-support">
              <p> Customer Support: <br/> +1 (215) 966-4473</p>
            </div>
          </div>
          <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
            <Link to="/home" className="nav-link">Home</Link>
            <Link to="/contact" className="nav-link">Contact Us</Link>
            <Link to="/about" className="nav-link">About Us</Link>
          </div>
          <div className="navbar-toggle" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
