import React from 'react';
import './CookiePolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const Disclaimer = () => {
  return (
    <div>
      <Navbar />
      <br /><br /><br /><br />
      <div className="policy-container">
        <div className="breadcrumb">
          <h2>Disclaimer</h2>
        </div>
        <div className="policy-content">
          <div className="info-box">
            <div className="info-icon">
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <div className="info-details">
              <h3>Disclaimer</h3>
              <p>We disclaim any liability...</p>
            </div>
          </div>
        </div>
        <div className="policy-body">
          <h1>DISCLAIMER</h1>
          <p>Last updated July 13, 2024</p>
          <h2>WEBSITE DISCLAIMER</h2>
          <p>
The information provided by Global Auto Experts LLC ("we," "us," or "our") on https://www.globalautoexperts.us (the "Site") is for general informational purposes only. All information on the Site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>

<p>UNDER NO CIRCUMSTANCES SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Disclaimer;
