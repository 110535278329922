import React from 'react';
import './ContactUs.css';
import Navbar from './Navbar';
import Footer from './Footer';

const ContactUs = () => {
  return (
    <div>
        <Navbar/> <br/> <br/>
        <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>
      <p className="contact-subtitle">Proudly Serving the U.S.A.</p>
      <div className="contact-info">
        <p><strong>Global Auto Experts</strong></p>
        <p>30S 15TH ST STE 1550 NUM 173221</p>
        <p>PHILADELPHIA, PA 19102</p>
        <p>Email: <a href="mailto:info@globalautoexperts.us">info@globalautoexperts.us</a></p>
        <p>Phone: +1 215-966-4473</p>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ContactUs;
